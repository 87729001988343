import React from "react"
import Layout from "../components/layout"

const ThirdPage = () => (
  <Layout>
    <h1>Hello from the third page</h1>
  </Layout>
)

export default ThirdPage
